import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu'; 
import { IconComponent } from './components/icon/icon.component'; 
import { TranslatePipe } from '../__core/pipes/translate.pipe';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {MatButtonToggleModule} from '@angular/material/button-toggle';


// Components 
import { ImageLoaderComponent } from '../__core/components/image-loader/image-loader.component';
import { PopupComponent } from '../__core/components/popup/popup.component';  
import { GoRegisterOrLoginComponent } from '../__core/dialogs/go-register-or-login/go-register-or-login.component';
import { ViewAllLoockupComponent } from './components/view-all-loockup/view-all-loockup.component';
import { SidenavItemsComponent } from './components/sidenav-items/sidenav-items.component';
import { ConfirmDeleteMessageComponent } from './components/confirm-delete-message/confirm-delete-message.component';
import { AuthComponent } from '../pages/auth/auth.component';
import { LoginComponent } from '../pages/auth/components/login/login.component';
import { ResetPasswordComponent } from '../pages/auth/components/reset-password/reset-password.component';
import { SignUpComponent } from '../pages/auth/components/sign-up/sign-up.component';
import { FooterComponent } from '../pages/__footer/footer.component';
import { LightboxImgComponent } from '../__core/components/lightbox-img/lightbox-img.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoaderComponent } from '../__core/components/loader/loader.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { ExperimentalLaunchDialogComponent } from './components/experimental-launch-dialog/experimental-launch-dialog.component';
import { VerificationCodeDialogComponent } from '../pages/form-car/dialogs/verification-code-dialog/verification-code-dialog.component';
import { CodeInputModule } from 'angular-code-input';


const shared = [
  CarouselModule,
  MatToolbarModule,
  MatInputModule,
  MatSidenavModule,
  MatIconModule,
  MatCardModule,
  MatDividerModule,
  HttpClientModule,
  MatButtonModule,
  MatRadioModule,
  MatListModule,
  MatRippleModule,
  MatTooltipModule,
  MatMenuModule,
  TranslateModule, 
  MatSelectModule,
  ReactiveFormsModule,
  FormsModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatButtonToggleModule,   
  NgxSpinnerModule,
  CodeInputModule,
];

@NgModule({
  declarations: [
    IconComponent,
    TranslatePipe, 
    ImageLoaderComponent,
    PopupComponent,  
    GoRegisterOrLoginComponent,
    ViewAllLoockupComponent,
    SidenavItemsComponent, 
    ConfirmDeleteMessageComponent,
    AuthComponent,
    LoginComponent,
    ResetPasswordComponent,
    SignUpComponent,
    FooterComponent,
    LightboxImgComponent,
    LoaderComponent,
    SkeletonComponent,
    ExperimentalLaunchDialogComponent,
    VerificationCodeDialogComponent 
  ],
  imports: [
    CommonModule, 
    
    [...shared]
  ],
  exports: [
    [...shared],
    IconComponent,
    TranslatePipe, 
    ImageLoaderComponent,
    PopupComponent,
    GoRegisterOrLoginComponent,
    SidenavItemsComponent,
    ConfirmDeleteMessageComponent,
    AuthComponent,
    LoginComponent,
    ResetPasswordComponent,
    SignUpComponent,
    FooterComponent,
    LightboxImgComponent,
    NgxSpinnerModule,
    LoaderComponent,
    SkeletonComponent,
    ExperimentalLaunchDialogComponent,
    VerificationCodeDialogComponent 
  ],
  providers: [DatePipe],

})
export class SharedModule { }
