export const English = {
 
    // snackbarMessages
    emailIsRequired: "This email address is not complete or valid",  
    nameIsRequired: "Name is Required",
    passwordIsRequired: "Password is required.",
    fullNameIsRequired: "Full name is required",
    mobileNumberIsRequired: "Mobile number is required",
    passwordChangedSuccessfully: "Password Changed Successfully",
    youMustLoginIn: "You must be logged in",
    yourEmailCreatedSuccessfully: "Your Email has Created Successfully",

    // DIALOGS
    verifyHeaderDialog: "Verify Your Email Address",
    verifyBodyDialog: "Do u want to verify your email ??",
    verify: "Verify",
    cancel: "Cancel",
    

    // Welcome
    welocme: "Welcome",
    usernameOrPasswordIsIncorrect: "Username or password is incorrect",
    somethingHasWentWrong: "Something Has Went Wrong",
    EmailIsTaken: "Email is taken",
    userNotFound: "User not found",
    checkEmail: "Check your email",
    userNotVerifiedYet: "User Not Verified Yet",
    passwordMissMatch: "The password does not match",


    // Header
    allCities: "All Cities (KSA)",
    favorite: "Favorites",
    chats: "Chats",
    notifocations: "Notifications",
    loginOrSignUp: "Login or Sign Up",
    publishYourAd: "Publish Your Ad",


    // FOOTER
    company: "Company",
    aboutUs: "About Us",
    advertising: "Advertising",
    careers: "Careers",
    termsOfUse: "Terms Of Use",
    privacyPolicy: "Privacy Policy",
    getSocial: "Get Social",
    facebook: "Facebook",
    twitter: "Twitter",
    youtube: "Youtube",
    instagram: "Instagram",
    support: "Support",
    help: "Help",
    contactUs: "Contact Us",
    languages: "Languages",
    english: "English",
    arabic: "العربية",
    downloadOurApp: "Download Our App",
    allRightsReserved: "All Rights Reserved",

    // header 
    login: "Login",

    // homePage 
    headerHomePage: "Compare prices …  secure your car ",
    comparePrices: "Compare prices … ",
    secureYourCar: "secure your car",
    andGetYourInsurancePolicyInMinutes: "and get your insurance policy in minutes",
    secondaryHeaderHomePageLine1: "Ammeni will help you compare the prices of more than 20 car insurance companies",
    secondaryHeaderHomePageLine2: "Certified and insured your car online",
    aboutUsHeader: "Ammeni ... Insure your car while you are comfortable",
    enterYourInformation: "Enter Your Information",
    compareYourPrices: "Compare your Prices",
    InsureYourCar: "Insure your Car",
    featureHeader: "What makes Ammeni different?!",
    aboutUsBox1_text: "Enter the ID / residence / company number and the car plate number and leave the rest to us",
    aboutUsBox2_text: "ompare the prices of insurance companies and choose the right policy characteristics for you",
    aboutUsBox3_text: "Pay and receive your insurance instantly and easily", 
    featureBox1_header: "Uniform prices",
    featureBox2_header: "Direct link with Najm/Al Muroor",
    featureBox3_header: "Our steps are easy",
    featureBox4_header: "Separate it how are you",
    featureBox1_text: "Our prices will be lower or like the prices of insurance companies, it is impossible to be more expensive",
    featureBox2_text: "Instant electronic link with Najm / Traffic, you can renew the form in Absher 24 hours",
    featureBox3_text: "In less than two minutes, you will be able to choose your policy and secure your car",
    featureBox4_text: "You can choose and separate your document as you like and add any coverage you need",
    contactUsHeader: "What would you like to know too?",
    contactUsSubHeader: "We have the answer to all the questions you have",
    instantChat: "Instant Chat",
    commonQuestions: "Common Questions",
    andYouCanAlso: "And you can also ",
    atAnyTime: "at any time",
    informationAboutUs: "Information About Us",
    carInsurance: "Car Insurance",
    newInsurance: "New Insurance",
    transferOfOwnership: "Transfer of Ownership",
    insuranceBodyHeader: "Comprehensive / third party vehicle insurance",
    showMeResult: "Show me Result",
    errorIdNumber: "The entered ID number is incorrect, please re-enter the correct ID number",
    idNumber: "ID / Residence / Company number",
    effectiveDateDocument: "Effective date of the document",
    thisFieldIsReuired: "This Field Is Required",
    aggreement: "I agree to grant Umni the right to inquire from any relevant party about my data or any other data",
    invalidCaptcha: "Invalid Captcha",
    drivers: "Drivers",
    car: "Car",
    slaes: "Slaes",
    checkout: "Details and Prices",
    varifyText: "All your information is safe with us, it is sent only through government channels and official insurance companies",
    DocumentOwnerInformation: "Document Owner Information",
    id: "ID Number",
    dateOfBirth: "Date of Birth (as in Iqama/ID)",
    year: "Year",
    month: "Month",
    moreDetails: "+ More Details",
    pleaseFillAllRequiredFields: "Please fill out Required Fields",
    somethingHasWentWronge: "Something has went Wronge, Please Try Again Later ",
    otherDetailsForTheDocumentOwner: "Other Details for the Document Owner",
    addDetails: "Add Details",
    education: "Education", 
    None: "None",
    HighSchool: "HighSchool",
    Diploma: "Diploma",
    Bachelor: "Bachelor",
    Master: "Master",
    PhD: "PhD",
    childrenUnder16: "Number of children under 16",
    driverAddress: "Client name and address",
    accedant: "Accedant",
    accedantDescription: "Does the driver have any previous accidents?",
    healthRestrictions: "Health Restrictions",
    healthRestrictionsDescription: "Does the driver have health conditions or license restrictions?",
    trafficViolations: "Traffic Violations",
    trafficViolationsDescription: "Does the driver have traffic violations?",
    drivingLicenseFromOtherCountries: "Driving license from other countries",
    drivingLicenseFromOtherCountriesDescription: "Does the driver have a driver's license from other countries?",
    issuanceCountry: "Issuance Country",
    numberOfYearsHoldingTheLicense: "Number of years holding the license",
    add: "Add",
    country: "Country",
    numberOfYears: "Number of Years",
    delete: "Delete",
    pleaseAddAtLeastCountry: "Please Add at Least one Country",
    formHasBeenAddedSuccessfully: "Form has been Added Successfully",
    theCountryisAlreadyChoosen: "The Country is Already Choosen",
    showOrEdit: "Show/Edit",
    ThereIsNo: "There is no",
    moreThanTen: "More Than 10",
    moreThanFive: "More Than 5",
    years: "Years",
    contirmDeleteMessageTitle: "Confirm Message",
    contirmDeleteMessageDesctioption: "Are you sure to delete this item ?",
    getDetails: "Get Details", 
    name: "Name",
    doYouWantToAddOtherDriversToTheVehicle: "Do you want to add other Drivers to the Vehicle?",
    extraDriverDescription: "If you are not sure, don't worry you can add more drivers",
    addDriver: "+ Add Driver",
    DriverIdNo: "Driver ID No.",
    DateOfBirth: "Date of Birth (as in Iqama/ID)",
    relativeRelation: "Relative Relation",
    enterDriverId: "Enter Driver Iqama Id",
    houseAddress: "House Address",
    pledge: "I undertake that the driver's relationship with the owner of the document is correct" ,
    sameOwnerFormerLocation: "Same address as the owner of the document",
    addedDriver: "added driver",
    dublicateRecorde: "Dublicate Recorde",
    carTitle: "Vehicle document type",
    istimara: "Istimara",
    customsCard: "Customs Card",
    serialNumber: "Serial Number",
    vehicleSerialNumber: "Serial Number",
    chooseThePurposeOfUsingTheVehicle: "Choose the purpose of using the vehicle",
    modelYear: "Model Year",
    purposeOfUse: "Purpose Of Use",
    getInfo: "Get Info",
    whereToParkTheVehicleAtNight: "Where to park the vehicle at night",
    numberOfAccedant: "Number Of Accedant",
    expectedMiles: "The expected number of kilometers to travel each year",
    transmissionType: "Transmission Type",
    normalGear: "Manual transmission",
    automaticGear: "Automatic transmission",
    yes: "Yes",
    no: "No",
    isEditOnVehicle: "Are there any modifications to the vehicle?",
    pleaseWriteTheModifications: "Please write the modifications",
    numberOfTrafficsViolations: "Number of Traffics Violations",
    numberCustomsCard: "number Card",
    offerList: "Offer List",
    pricingTimeWillExpireIn: "Pricing time will expire in",
    hours: "Hours",
    minutes: "Minutes",
    seconds: "Seconds",
    chooseInsuranceType: "Choose Insurance Type",
    tplOffer: "third party Insurance",
    cmpOffer: "comprehensive Insurance",
    pricesstartedFrom: "started from 3499.00 RS",
    continue: "Continue",
    back: "Back", 
    youMustPledgeTheValidityOfTheRelationship: "You must pledge the validity of the relationship",
    updateDriver: "Update Driver",
    pleasAddYourInfo: "Pleas Add Your Information with details",
    pleasAddYourCarInfo: "Pleas Add Your Car Information",
    carNotFound: "Car Not Found",
    chassisNumber: "chassisNumber",
    cylinders: "cylinders",
    licenseExpiryDate: "licenseExpiryDate",
    majorColor: "Major Color",
    plateNumber: "Plate Number", 
    plateTypeDesc: "Plate Type Description",
    sequenceNumber: "Sequence Number",
    vehicleMakerName: "Vehicle Maker Name",
    vehicleModelName: "Vehicle Model Name",
    plateText1: "Plate Text 1",
    plateText2: "Plate Text 2",
    plateText3: "Plate Text 3",
    vehicleLoad: "Vehicle Load",
    vehicleWeight: "Vehicle Weight",
    TheNumberExpectedToBeCutEachYear: "The number expected to be cut each year",
    CarDetails: "More information for car",
    connectionSpead: "Connection Spead",
    insuranceCoverage: "Insurance Coverage",
    additionalCovers : "Additional Covers",
    ownerDocumentAndCarInformation: "Owner Document and Car Information",
    documentStartedDate: "Document Started Date",
    address: "Address",
    sortByPrice: "Sort by Price",
    heightToLow: "From the highest price to the lowest",
    lowToHight: "From the lowest price to the highest",
    filter: "Filter",
    seeMore: "See More",
    seeLess: "See Less",
    monthMiladi1: "January",
    monthMiladi2: "February",
    monthMiladi3: "March",
    monthMiladi4: "April",
    monthMiladi5: "May",
    monthMiladi6: "June",
    monthMiladi7: "July",
    monthMiladi8: "August",
    monthMiladi9: "September",
    monthMiladi10: "October",
    monthMiladi11: "November",
    monthMiladi12: "December", 
    monthHijri1: "Muharram",
    monthHijri2: "Safar",
    monthHijri3: "Rabi al-Awwal",
    monthHijri4: "Rabi al-Thani",
    monthHijri5: "Jumada al-Awwal",
    monthHijri6: "Jumada al-Thani",
    monthHijri7: "Rajab",
    monthHijri8: "Sha’ban",
    monthHijri9: "Ramadan",
    monthHijri10: "Shawwal",
    monthHijri11: "Dhul–Qidah",
    monthHijri12: "Dhul–Hijjah", 
    summerDiscount: "Summer Discount",
    discount: "190.00 SAR (10.00%)",
    rs: "R.S",
    termesAndConditon: "Termes and Conditon",
    choose: "Choose",
    make: "Vehicle Model",
    vehicleInformation: "Vehicle Information",
    vehicleColor: "Vehicle Color",
    driversInformation: "Drivers Information",
    sharePrice: "Share Price",
    youCanSharePriceAndPayLaterOn: "You Can Share the Price and Pay Later on",
    copy: "Copy Link",
    share: "Share Price",
    documentPrice: "Document Price",
    details: "Details",
    insuranceSubscriptionPremium: "Insurance Subscription Premium",
    subTotal: "Sub Total",
    tax: "Value Added Tax (15%)",
    grandTotal: "Grand Total",
    insuranceType: "نوع التأمين",
    fixIn: "fixIn",
    documentExpiryDate: "Document Expiry Date",
    EnduranceValue: "Endurance value",
    vehicleCashValue: "Vehicle Cash Value",
    insuranceWith: "Insurance With",
    payment: "Payment",
    iban: "The document holder’s international account number (IBAN)",
    enterIban: "Enter the IBAN number",
    IBANWillBeSavedToYourAccount: "Your IBAN will be saved to your account",
    documentOwnersEmail: "E-mail of the owner of the document",
    documentOwnersMobileNumber: "The document owner's mobile number",
    enterValidEmail: "Enter a valid email",
    chooseWayOfPayment: "Choose Way of Payment",
    madaBankCard: "mada bank card",
    masterCard: "Master Card",
    visa: "Visa",
    sada: "Saddad",
    iApproveOn: "I Approve On",
    PayNow: "Pay now",
    backToOffers: "Back To Offers",
    goProfile: "My Profile",
    close: "Close",
    signUp: "Sigin Up",
    LogInToYourAccount: "LogIn to your Address",
    email: "Email Account",
    enterYourEmail: "Please Enter Your Email Address",
    enterYourPassword:"Enter You Password",
    password: "Password",
    forgetPassword: "Forget your Password?",
    dontHaveAnAccount: "Don't have an Account",
    registerYourAccount: "Register your Account",
    createNewAccount: "Create new Account",
    phoneNumber: "Mobile Number",
    haveAnAccount: "Have an Account"    ,
    backToLogin: "Back to Login",
    restorePassword: "Restore Password",
    sendWerificationEmail: "We will send a verification code to your email",
    verificationCode: "Verification Code",
    enterYourVerificationCode: "Enter Verification Code",
    newPassword: "New Password",
    enterYourNewPassword: "EnterYourNewPassword",
    confirmNewPassword: "Confirm new Password",
    loggedInSuccessfully: "logged In Successfully",
    confirmPassword: "Confirm Password",
    signUpInSuccessfully: "Sign Up InSuccessfully",
    enterVerificationCode: "Please enter the verification code sent to your mobile Number",
    yourAccountIsVerifiedSuccessfully: "Your Account is Verified Successfully",
    send: "Send",
    yourPasswordIsReseted: "your password is reseted",
    aboutCompany: "About Company",
    products: "Products",
    insureCar: "The Car Insurance",
    vehicleValue: "Vehicle Amount",
    drivingRate: "Driving Rate",
    driverDeletedSuccessfully: "Driver Has Been Deleted Successfully",
    theSummationOfParcentageMustBeEqual100: "The Summation Of Driving Rate Parcentage Must be Equal 100%",
    enduranceValue: "Endurance Value",
    pleaseLoginIn: "You Must be Logged In",
    youHaveToPledgeToSaveThe: "You have to pledge to save the IBAN",
    paymentHasBeenDoneSuccessfullay: "Payment has been Done Successfullay",
    comprehensiveInsuranceRequirements: "Comprehensive Insurance Requirements",
    pleaseEnterRightIBAN: "Please Enter Right IBAN Number ",
    frontSide: "Front side",
    backSide: "Back side",
    rightSide: "Right side",
    leftSide: "Left side", 
    savePageAndGetBackanotherTime: "is not you photographed the car yet? no worries save page and keep on later on",
    savePage: "Save Page",
    plageImages: "I acknowledge that the uploaded photos are the real photos of the insured vehicle, which were taken recently and have not been modified in any way. I acknowledge my knowledge that if proven otherwise, this will lead to the cancellation of the insurance policy and will affect the claims filed on the vehicle.",
    congratulationsInsurance: "Congratulations Insurance",
    insuranceHasBeenPurchasedSuccessfully: "The Insurance Has Been Purchased Successfully",
    voucherNumber: "Voucher Number",
    voucherDetails: "Voucher Details",
    Ammeni: "Ammeni",
    billerName: "Biller Name",
    billerNumber: "Biller Number",
    totalDocumentPrice: "Total Document Price",
    documentOwner: "Owner",
    pricingReferenceNumber: "Pricing reference number",
    weSendMessageToU: "We will send a text message to your phone number as soon as the document is issued",
    goToMyProfile: "Go to my Profile",
    ImageUploadedSuccessfully: "Image Uploaded Successfully",
    payNewInsurance: "Pay a New Insurance",
    myProfile: "My Profile",
    myVehicles: "My Vehicles",
    insuranceDocuments: "Insurance Documents",
    settings: "Settings",
    welcome: "Welcome",
    registerdInentities: "Registerd Inentities",
    registerdInentitiesDescription: "These identities will be shown in the selection list on any new purchase",
    theDocumentOwnersIdNumber: "The document owner's ID number",
    buyItBeforeItGone: "Buy It Before it Gone",
    completePurchaseProcess: "Complete Purchase Process",
    recoveryDocumentInsurance: "Recovery Document Insurance",
    validDocuments: "Valid Documents",
    nearingExpiryDocuments: "Nearing Expiry Documents",
    expiredDocuments: "Expired Documents",
    renewedDocuments: "Renewed Documents",
    invoices: "Invoices", 
    type: "Vehicle Type",
    owner: "Owner",
    description: "Description",
    status: "Status",
    showDocumentDetails: "Show Document Details",
    thereIsNoAnyVehiclesRegisterdYet: "There is no any Vehicles Registerd yet",
    vehicleDeatils: "Vehicle Deatils",
    idAndIqamma: "Inentity Number / iqama",
    moreInfo: "More Details",
    show: "Show",
    deleteVehicle: "Delete Vehicle",
    thereIsNoValidInsuranceDocuments: "There is no Valid Insurance Documents",
    authorizedBy: "authorized by",
    saudiCentralBank: "Saudi Central Bank",
    driverDeletedSuccessfuly: "Driver Deleted Successfuly", 
    expirtDate: "Expirt Date", 
    cardNumber: "Card Number",
    cardName: "Card Name",
    enterCardInformation: "Enter Information Card",
    youMustPladgeOnTermsOfUse: "You must Pladge on Terms and Conditions",
    youMustPladgeOnCarImages: "You must Pladge on images car",
    youMustChoosePaymentMethod: "You Must Choose Payment Method",
    youMustPadgeOnSavingIBANAccount: "You must Padge on saving IBAN account",
    thereAreNoValidInsuranceDocuments: "There are no  ",
    Pending: "Pending",
    QUOTE: "QUOTE",
    DRAFT: "DRAFT",
    ISSUED: "ISSUED",
    Cancelled: "Cancelled",
    Expired: "Expired",
    monthValueIsInvalid: "Please Enter a Valid Month",
    yearValueIsInvalid: "Please Enter a Valid Year",
    thereIsNoOfffers: "There is no offfers",
    PendingOrders : "Pending Orders",
    Price: "Price",
    ExpiryDate: "Expiry Date",
    View : "View",
    policyDetails: "Policy Details",
    print: "Print",
    additionalCoveragesPrice: "Additional Coverages Price",
    total: "Total",
    administrativeExpenses: "Administrative expenses",
    iqamaNumber: "Iqama Number",
    mobileNumber: "Mobile Number",
    birthDate: "Birth Date",
    birthDateHijri: "Birth Date Hijri",
    DocumentOwnerAndVehicleInformation: "Document Owner And Vehicle Information",
    repairCondition: "Repair Condition",
    sumInsured: "Sum Insured",
    AllOffers: "All Offers",
    ExpiredOfferNote: "Offer is Expiered.",
    NoOffersFound: "No Available Offers right now, Please try again later.",
    InsuranceCompany: "Insurance Company",
    ConfirmAction: "Confirm Action",
    ProposalInfo: "Proposal Info",
    SelectOfferConfirmation: "Yes, Select This Offer",
    NcdDiscount: "No Claims Discount",
    PickThisOffer: "Pick This Offer?",
    NetPrice: "Net Price",
    OfferedBy: "Offered By",
    Subject : "Subject",
    Name: "Name",
    Mobile: "Mobile",
    Email: "Email",
    Details: "Details",
    Submit: "Submit",
    CustomerService: "Customer Service",
    WhatsApp: "WhatsApp",
    CustomerServiceParagraph: "",
    contactUsParagraph: "",
    emailParagraph: "",
    ContactUsThankYouMessage: "Thank You For contacting us, We will review your message ASAP.",
    MessageSent: "Your Message has been sent.",
    YourMessageGasBeenSent: "Your Message has been sent successfully.",
    OurTeamReviewMessage: "Our Team Will review your message and we will be in touch ASAP",
    SupportCenterWelcome : "Hello, How can we Help?",
    faqSearchBoxPlaceHolder: "Search For anything in FAQs",
    logout: "Logout",
    NeedKnowMore: "Wants to know more?",
    WeHaveAnswers: "We Have Answer for every Question.",
    PersonalInfo: "Personal Info",
    Notifications: "Notifications",
    currentPassword: "Current Password",
    DataSaved: "Info Saved successfully.",
    CurrentMobile: "Current Mobile Number",
    NewMobileNumber: "New Mobile Number",
    SendVerificationCode: "Send Verification Code",
    VerificationCode: "Verification Code",
    codeSentToMobile: "Verification Code sent to your mobile, Please fill it in the required field",
    codeSentToEmail: "Verification Code sent to your new email address, Please fill it in the required field",
    offerType: "Insurance Type",
    View_All_Offers: "View All Offers",
    TPL_Offers: "TPL Offers",
    COMP_Offers: "COMP Offers",
    provideVehicleValue: "Please Provide Vehicle Value",
    newMobileIsRequired: "New Mobile Number is Required",
    verificationCodeIsRequired: "Verification Code Is Required",
    MobileChangedSuccessfully: "Mobile Number Changed Successfully.",
    EmailChangedSuccessfully: "Email Changed Successfully.",
    CurrentEmail: "Current Email",
    NewEmail: "New Email",
    newOwner: "New Owner",
    currentOwner: "Current Owner",
    PleaseFillNewOwnerId: "Please Fill New Owner Id",
    PleaseFillCurrentOwnerId: "Please Fill Current Owner Id", 
    receiveNotifications: "Receive Notifications",
    beforeTheDocumentExpires: "Before The Document Expiers",
    whenTheDocumentExpires: "When The Document Expires",
    days: "Days",
    notificationSettingsSavedSuccessfully: "Notification Settings Saved Successfully",
    medicalInsurance: "Medical malpractice insurance",
    travelInsurance: "Travel Insurance", 
    perpuseOfTravel: "Perpuse of Travel",
    typeOfTravel: "Type of Travel",
    destination: "Travel destination ",
    yourStatus: "Your Status",
    TransferOfOwnership: "Transfer of ownership",
    TrvelInformation: "Trvel Information",
    travelDate: "Travel date",
    returnDate: "Return Date",
    countries: "Countries",
    addTraveler: "Add Traveler", 
    tripAndTravelersInfo: "Trip and travelers info",
    pleasAddTripInfo: "Please add info Trip",
    pleasTravelerAtLeast: "Please add one Traveler At Least",
    trpInfo: "Trip Info",
    travelPerpuse: "travel Perpuse",
    travelType: "travel Type",
    travelDistenation: "travel Distenation",
    travelStartingDate: "travel Starting Date",
    travelEndingDate: "travel Ending Date", 
    typeOfTravelHint: "If you plan to travel to the same destination several times during the coming period, this selection will be presented for the best travel insurance suitable for you.",
    documentOwnerInfo: "Document owner info",
    documentAndMedicalSpecialty: "Document and medical specialty",
    StatementsOfTheDocumentOwner: "statements of the document owner",
    doYouPerformSurgeriesOrParticipateInSurgeries: "Do you perform surgeries or participate in surgeries",
    documentStartingDate: "Document starting date",
    numbersOfExperienceYears: "Numbers of experience years",
    rejoinPracticeWork: "Rejoin practice work",
    rejoinWork: "Rejoin work",
    medicalSpecialty: "Medical specialty",
    doYouPerformSurgeries: "Do you perform surgeries",
    ExperimentalLaunch: "Experimental Launch",
    medicalStatemetsQ1: "Do you have a valid or expired medical malpractice insurance policy?",
    medicalStatemetsQ2: "Are there any claims or lawsuits filed against you for negligence or malpractice within the past ten years?",
    medicalStatemetsQ3: "Are you aware of any claims or lawsuits filed against any of your partners, personal assistants, nurses, or technicians employed by you for negligence or malpractice within the past ten years?",
    medicalStatemetsQ4: "Have any insurance companies ever refused, canceled, or accepted under specific conditions your application for medical malpractice insurance, whether it was a new or renewal application?",
    medicalStatemetsQ5: "Are you aware of any current events or circumstances that may lead to claims or lawsuits against you in the future?",
    medicalStatemetsQ6: "Have you ever been convicted of a crime (besides traffic violations) or subjected to disciplinary actions or reprimand by any administrative agency belonging to a professional association?",
    medicalStatemetsQ7: "Have you successfully completed a criminal rehabilitation program with the relevant authorities?",
    cancellationReason: "cancellationReason",
    cancelRequest: "Cancel insurrance Request",
    uploadFileRejection: "Upload rejection file",
    pleaseUploadFileRejection: "Please upload file rejection",
    requestDeleteUnderProccessing: "Request delete is under proccessing",
    insuranceAuthority: "insurance authority",
    pleaseWait: "Please wait",
    visaAndMasterCard: "Visa / MasterCard"
 

}