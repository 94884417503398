import { FormControl, FormGroup, Validators, FormControlName, AbstractControl } from '@angular/forms';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppService } from 'src/app/__core/services/app.service';
import { TranslationService } from 'src/app/__core/services/translation.service';
import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
import { MatSnackBarService } from 'src/app/__core/services/matSnackBar.service';
import { AuthService } from '../../auth.service';
import { SharedService } from 'src/app/__shared/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ess-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  animations: [
    trigger('stagger', [
      transition('* => *', [
        query(':enter', stagger(60, animateChild()), { optional: true }),
      ])
    ]),
    trigger('fadeInUp', [
      transition(':enter', [
        style({
          transform: 'translateY(50px)',
          opacity: 0
        }),
        animate(`500ms cubic-bezier(0.35, 0, 0.25, 1)`, style({
          transform: 'translateY(0)',
          opacity: 1
        }))
      ]),
      transition(':leave', [
        style({
          transform: 'translateX(0)',
          opacity: 1
        }),
        animate(`500ms cubic-bezier(0.35, 0, 0.25, 1)`, style({
          transform: 'translateX(50px)',
          opacity: 0
        }))
      ]),
    ])
  ],
})
export class SignUpComponent implements OnInit, AfterViewInit {

  RegistrationId: string = "5bf47f10-2e5d-4fec-c52b-08dcbd11bbc7"

  @Output() navigationTo = new EventEmitter();
  @Output() close = new EventEmitter();

  isLightboxOpen: boolean = false;
  lightboxImage: any;

  pageName: string = "login";
  baseHeaderImagePath: string = "assets/images/_header/";
  currentLanguage: any = this.lang.currentLanguage;
  form: FormGroup
  currentStep: 'registerStep' | 'verificationCodeStep' = 'registerStep';
  verificationCode = new FormControl();
  imageCapcha: string = ""
  tokenCapcha: string = ""

  constructor(
    public app: AppService,
    private lang: TranslationService,
    private snack: MatSnackBarService,
    private service: AuthService,
    private cd: ChangeDetectorRef,
    private sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      phoneNumber: new FormControl('', Validators.required),
      email: new FormControl('', Validators.compose([
        Validators.required, Validators.email
      ])),
      IdNumber: new FormControl('', Validators.compose([
        this.sharedService.checkValidityNumber,
        Validators.required
      ])),
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', Validators.required),
      CaptchaString: new FormControl('', Validators.required),
    })
  }

  ngAfterViewInit(): void {
    this.GetCaptcha();
  }

  navigationPageTo = (pageName: any) => {
    this.navigationTo.emit(pageName)
  }

  closePopup = () => {
    this.close.emit(true)
  }


  GetCaptcha = () => {
    this.sharedService.GetCaptcha().subscribe(res => {
      if (res.errorCode == 0) {
        this.imageCapcha = (environment.baseUrl + "File/" + res.data?.imagePath || "")
        this.tokenCapcha = res.data?.token || "";
        this.cd.detectChanges()
      } else {
        this.snack.error(res.errorMessage);
      }
    })
  }


  signUp = () => {
    if (this.form.invalid) {
      this.snack.error(this.lang.translate('pleaseFillAllRequiredFields'));
      return;
    }

    let req = new FormData();
    req.append('MobileNumber', this.form.get('phoneNumber')?.value);
    req.append('Email', this.form.get('email')?.value);
    req.append('Password', this.form.get('password')?.value);
    req.append('ConfirmPassword', this.form.get('confirmPassword')?.value);


    req.append('IdNumber', this.form.get('IdNumber')?.value);
    req.append('CaptchaToken', this.tokenCapcha);
    req.append('CaptchaString', this.form.get('CaptchaString')?.value);



    this.service.signUp(req).subscribe(res => {
      if (res.errorCode == 0) {
        this.RegistrationId = res.data;
        this.currentStep = 'verificationCodeStep';
        this.cd.markForCheck();
      } else {
        this.snack.error(res.errorMessage);
      }
    })

  }


  verify = () => {
    if (this.verificationCode.invalid) {
      this.snack.error(this.lang.translate('pleaseFillAllRequiredFields'));
      this.form.markAllAsTouched();
      return;
    }

    let req = new FormData();
    req.append('RegistrationId', this.RegistrationId);
    req.append('VerificationCode', this.verificationCode?.value);

    this.service.verifyRegister(req).subscribe(res => {
      if (res.errorCode == 0) {
        this.snack.success(this.lang.translate('yourAccountIsVerifiedSuccessfully'))
        this.navigationTo.emit('login');
      } else {
        this.snack.error(res.errorMessage);
      }
    })
  }

  openLightbox = (img: any) => {
    this.lightboxImage = img;
    this.isLightboxOpen = true;
    this.cd.detectChanges();
  }

  dismiss = () => { 
    this.lightboxImage = null;
    this.isLightboxOpen = false;
    this.cd.detectChanges(); 
  }

}
