
import { environment as env } from "src/environments/environment";
export const base = env.baseUrl;

export const endPoints = {
    base: base,
    // Home 
    home: base + "Home",
    homeIds: base + "Home/MyRegisteredIds",
    getInsuranceCompaniesImage: base + "InsuranceCompanies/image/",
    getCapchaImage: base + "Captcha/get-captcha-image/",
    PurchaseOrders: base + "PurchaseOrders/request",
    GetDriver: base + "Motors/Drivers/AddExtraDriver",
    deleteExtraDriver: base + "Motors/Drivers/DeleteExtraDriver/",
    gethealthConditionsLookups: base + "Lookups/health-conditions",
    getclaimTypesLookups: base + "Lookups/claim-types",
    getCountries: base + "Lookups/countries", 
    getRelationships: base + "Lookups/relationships", 
    getVehiclePurposes: base + "Lookups/vehicle-purposes",
    getVehicleParking: base + "Lookups/vehicle-parking",
    VehicleMiles: base + "Lookups/expected-miles",
    vehicleByCustom: base + "Motors/Vehicles/GetCarByCustom",
    vehicleBySequence: base + "Motors/Vehicles/GetCarBySequence",
    vehicleByRequest: base + "Motors/Vehicles/vehicle-by-request/",
 
    save: base + "Motors/Orders/Save", 
    getProposals: base + "Motors/Orders/getProposals/", 
    getOffers: base + "Motors/Orders/GetProposal/",
 
    expiryChecker: base + "PurchaseOrders/expiry-check/",
    selectOffer: base + "Motors/Orders/selectProposal",
    login: base + 'Authenticate/login',
    signUp: base + 'Registration/register',
    verifyRegister: base + 'Registration/verify',
    resendVerifyRegister: base + 'Registration/resend-verification-code',
    forgetPassword: base + 'ForgotPassword/Initiate',
    verifyForgetPassword: base + 'ForgotPassword/VerifyCode',
    resetPassword: base + 'ForgotPassword/ResetPassword',
    getExtraDrivers: base + "Drivers/GetExtraDrivers/",
    checkout: base + "Motors/Orders/Checkout",


    // Change Vehicle Images
    getFrontImage: base + "Motors/Vehicles/getFrontImage/",
    uploadFrontImage: base + "Motors/Vehicles/UploadFrontImage/", 

    
    getBackImage: base + "Motors/Vehicles/getRearImage/",
    uploadBackImage: base + "Motors/Vehicles/uploadRearImage/", 


    getRightImage: base + "Motors/Vehicles/getRightImage/",
    uploadRightImage: base + "Motors/Vehicles/uploadRightImage/",
 

    getLeftImage: base + "Motors/Vehicles/getFrontImage/",
    uploadLeftImage: base + "Motors/Vehicles/uploadLeftImage/",


    getPlateNumberImage: base + "Motors/Vehicles/getChassisNumberImage/",
    uploadPlateNumberImage: base + "Motors/Vehicles/uploadChassisNumberImage/",


    // Profile
    myProfileData: base + "Profile/Main",
    deleteDriver: base + "Profile/DeleteDriver/",
    voucherSammary: base+ "Motors/Orders/PaymentStatus/",
    getPolices: base + "Profile/Policies",
    deleteVehicle: base + "Profile/DeleteVehicle/",
    registerdIds : base + "Profile/RegisteredIds",
    profileVehicles : base + "Profile/Vehicles",

    PurchaseOrderSummary : base + "Motors/Orders/Summary/",
    PaymentStatus : base + "Motors/Orders/PaymentStatus/",
    GetPolicyDetails: base + "Policies/Get/",

    PrivacyPolicy: base + "Configuration/PrivacyPolicy",
    Terms: base + "Configuration/Terms",
    ConfigurationUrl: base + "Configuration",
    ContactUs: base + "ContactUs",

    FAQs: base + "Faqs/list",

    changePassword: base + "Accounts/change-password",
    updateUserInfo: base + "Accounts/update-info",
    getUserInfo: base + "Accounts/user-info",
    changeMobileCode: base + "Accounts/mobile/change/send-verification",
    changeMobileNumber: base + "Accounts/mobile/change/update",

    changeEmailCode: base + "Accounts/email/change/send-verification",
    changeEmail: base + "Accounts/email/change/update",


    // Notifications
    saveNotification: base + "Accounts/notifications/update",
    getNotification: base + "Accounts/notifications/get",

    CancelPolicy: base + "Policies/CancelPolicy"
}