import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { animateChild, query, stagger, style, transition, trigger, animate } from '@angular/animations';

@Component({
  selector: 'ess-lightbox-img',
  templateUrl: './lightbox-img.component.html',
  styleUrls: ['./lightbox-img.component.scss'],
  animations: [
    trigger('stagger', [
      transition('* => *', [
        query(':enter', stagger(60, animateChild()), { optional: true }),
      ])
    ]),
    trigger('fadeInUp', [
      transition('* => *', [
        style({
          transform: 'translateX(80px)',
          opacity: 0
        }),
        animate(`800ms cubic-bezier(0.35, 0, 0.25, 1)`, style({
          transform: 'translateX(0)',
          opacity: 1
        }))
      ]),
    ])
  ],
})
export class LightboxImgComponent { 
  @Input() Image: any 
  @Output() dismiss = new EventEmitter();


  close = () => {
    this.dismiss.emit(true);
  }

  in = () => {  
  }
  
  out = () => {  
  }
  
  
}
