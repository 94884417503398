<div class="container actions">
    <button (click)="navigationPageTo('login')"> {{ 'login' | translate }} </button>
    <button (click)="navigationPageTo('signUp')" class="active"> {{ 'signUp' | translate }} </button>
</div>
<section @fadeInUp class="auth-control">
    <div class="close cursor-pointer" (click)="closePopup()">
        {{ 'close' | translate }}
    </div>

    <div class="custom-container">
        <div class="auth-control-header">
            <img *ngIf="currentLanguage == 'ar'" [src]="baseHeaderImagePath + 'logoArBlack.svg'" width="150px" alt="">
            <img *ngIf="currentLanguage == 'en'" [src]="baseHeaderImagePath + 'logoEnBlack.svg'" width="150px" alt="">
        </div>
    </div>

    <div class="auth-control-body mt-3" style="overflow-y: scroll; max-height: 450px;">
        <div class="custom-container">
            <p class="my-3 f-bolder"> {{ 'createNewAccount' | translate }} </p>

            <div class="row" [formGroup]="form" *ngIf="currentStep == 'registerStep' ">


                <!-- phoneNumber -->
                <div class="col-md-6 my-1">
                    <mat-label class="f-bolder"> {{ 'phoneNumber' | translate }} </mat-label>
                    <mat-form-field appearance="outline">
                        <input type="number" matInput maxlength="10" formControlName="phoneNumber"
                            placeholder="05XXXXXXXX">
                        <mat-error>
                            <p> {{ 'mobileNumberIsRequired' | translate }} </p>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Email -->
                <div class="col-md-6 my-1">
                    <mat-label class="f-bolder"> {{ 'email' | translate }} </mat-label>
                    <mat-form-field appearance="outline">
                        <input type="text" matInput formControlName="email"
                            [placeholder]=" 'enterYourEmail' | translate ">
                        <mat-error>
                            <p> {{ 'emailIsRequired' | translate }} </p>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- idNumber -->
                <div class="col-md-6 my-1">
                    <mat-label> {{ 'idNumber' | translate }} </mat-label>
                    <mat-form-field appearance="outline">
                        <input maxlength="10" autocomplete="off" matInput type="text" formControlName="IdNumber">
                        <mat-error>
                            <span *ngIf="form.get('IdNumber')?.value"> {{ 'errorIdNumber' | translate }} </span>
                            <span *ngIf="form.get('IdNumber')?.hasError('required')"> {{ 'thisFieldIsReuired' |
                                translate }} </span>
                        </mat-error>
                    </mat-form-field>
                </div>


                <!-- Password -->
                <div class="col-md-6 my-1">
                    <mat-label class="f-bolder"> {{ 'password' | translate }} </mat-label>
                    <mat-form-field appearance="outline">
                        <input type="password" matInput formControlName="password"
                            [placeholder]=" 'enterYourPassword' | translate ">
                        <mat-error> {{ 'passwordIsRequired' | translate }} </mat-error>
                    </mat-form-field>
                </div>

                <!-- confirmPassword -->
                <div class="col-md-6 my-1">
                    <mat-label class="f-bolder"> {{ 'confirmPassword' | translate }} </mat-label>
                    <mat-form-field appearance="outline">
                        <input type="password" matInput formControlName="confirmPassword"
                            [placeholder]=" 'enterYourPassword' | translate ">
                        <mat-error> {{ 'passwordIsRequired' | translate }} </mat-error>
                    </mat-form-field>
                </div>

                <!-- CAPCHAA -->
                <div class="col-sm-9 capchaa-cont">
                    <mat-form-field appearance="outline">
                        <img (click)="openLightbox(imageCapcha)"
                            style="display: flex; align-items: center; margin-top: 13px 0; height: 25px;" matPrefix
                            [src]="imageCapcha">

                        <img (click)="GetCaptcha()"
                            style="display: flex; align-items: center; margin-top: 9px; height: 100%; cursor: pointer;"
                            matSuffix [src]="'assets/images/_home/resenCodeIcon.svg'">

                        <input (keyup.enter)="signUp()" autocomplete="off"
                            style="padding: 0 10px; font-size: 20px !important;" matInput type="text"
                            formControlName="CaptchaString">
                        <mat-error> {{ 'thisFieldIsReuired' | translate }} </mat-error>
                    </mat-form-field>
                </div>



                <div class="col-md-12">
                    <button class="main-btn w-100" (click)="signUp()"> {{ 'signUp' | translate }} </button>
                    <div class="text-center my-3">
                        <span class="text-gray"> {{ 'haveAnAccount' | translate }} </span>
                        <span class="text-blue cursor-pointer" (click)="navigationPageTo('login')"> {{ 'login' |
                            translate }} </span>
                    </div>
                </div>

            </div>

            <div *ngIf="currentStep == 'verificationCodeStep' ">
                <p>
                    {{ ('enterVerificationCode' | translate) }}
                    <span style="font-weight: bolder;"> {{ form.get('phoneNumber')?.value }} </span>
                </p>
                <div class="row">
                    <div class="col-md-12 mt-3">
                        <mat-label class="f-bolder"> {{ 'verificationCode' | translate }} </mat-label>
                        <mat-form-field appearance="outline">
                            <input type="text" matInput [formControl]="verificationCode"
                                [placeholder]=" 'enterYourVerificationCode' | translate ">
                            <mat-error> {{ 'thisFieldIsReuired' | translate }} </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12">
                        <button class="main-btn w-100" (click)="verify()"> {{ 'verify' | translate }} </button>
                        <div class="text-center my-3">
                            <span class="text-gray"> {{ 'haveAnAccount' | translate }} </span>
                            <span class="text-blue cursor-pointer" (click)="navigationPageTo('login')"> {{ 'login' |
                                translate }} </span>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    </div>
</section>


<section *ngIf="isLightboxOpen">
    <ess-lightbox-img (dismiss)="dismiss()" [Image]="lightboxImage"></ess-lightbox-img>
</section>