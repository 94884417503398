import { MatSnackBarService } from '../services/matSnackBar.service';
import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router"; 
import { environment } from "src/environments/environment";
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpStatusCode, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators'; 
import { Location } from '@angular/common'; 
import { AppService } from '../services/app.service';
import { TranslationService } from '../services/translation.service';

@Injectable() 
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private snack: MatSnackBarService,
        private readonly location: Location,
        private route: Router,
        private languageService: TranslationService,
        private app: AppService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(req).pipe(

            catchError((error: HttpErrorResponse) => {

                if (error.error instanceof ErrorEvent) {

                    // Handing ServerSide Error is here
                    //
                    // 
                    
                } else {  
                    switch (error.status) {
                        case 0 : this.unKnownError(); break;
                        case HttpStatusCode.BadRequest: this.BadRequest(); break;
                        case HttpStatusCode.GatewayTimeout: this.GatewayTimeout(); break;
                        case HttpStatusCode.NotFound: this.NotFound(); break;
                        case HttpStatusCode.NetworkAuthenticationRequired: this.NetworkAuthenticationRequired(); break;
                        case HttpStatusCode.NotImplemented: this.NotImplemented(); break;
                        case HttpStatusCode.PaymentRequired: this.PaymentRequired(); break;
                        case HttpStatusCode.RequestTimeout: this.RequestTimeout(); break;  
                        case HttpStatusCode.InternalServerError: this.ServerDown(); break;  
                        case HttpStatusCode.Unauthorized: this.Unauthorized(); break;  
                        case HttpStatusCode.Forbidden: this.Unauthorized(); break;  
                        case 405: this.MethodNotAllowed(); break;
                        case 503: this.ServiceUnavailable(); break; 
                        case 505: this.HttpVersionNotSupport(); break; 
                    }

                }
                return throwError(error);

            })

        )
    }
    

    error = (errorMessage: string) => this.snack.error(errorMessage); 
    // error(errorMessage: string): void {
    // }
 
    Unauthorized = () => { 
        this.error(this.languageService.translate("pleaseLoginIn"));
        // this.route.navigate(['/login'])
        // localStorage.clear(); 
    }
    
    BadRequest = () => this.error("Bad Request, 400");

    PaymentRequired = () => this.error("Payment Required, 402");

    NotFound = () => this.error("Not Found, 404");

    MethodNotAllowed = () => this.error("Method Not Allowed, 405");

    ServerDown = () => this.error("Something has went wrong in server, 500");

    NotImplemented = () => this.error("Not Implemented, 501")

    ServiceUnavailable = () => this.error("Service Unavailable, 503") 

    GatewayTimeout = () => this.error("Gateway Timeout, 504");

    HttpVersionNotSupport = () => this.error("Http Version Not Support, 505"); 

    unKnownError = () => this.error("Un Known Error, Please Try Again Later on"); 

    RequestTimeout = () => this.error("RequestTimeout"); 

    NetworkAuthenticationRequired = () => this.error("Check your NetWork"); 
    
    
    
    
    
}




 