
import { Pipe, PipeTransform } from '@angular/core'; 
import { TranslationService } from '../services/translation.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(private service: TranslationService) {}

  transform = (label: string) => this.service.translate(label);

}
